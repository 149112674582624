<template>
	<div>
		<Modal
			:name="MANAGER_VARIABLE_MODAL_KEY"
			:eventBus="modalBus"
			:title="$locale.baseText('projectDetailing.tect_6')"
			width="95%"
			minHeight="min-content"
			maxHeight="603px"
			class="modal-manage"
		>
			<template v-slot:content>
				<ModalAliare :show="showModal" :width_container="widthEdit" :height_container="height" class="modal-addEdit">
			<template #header>
				<span>
					{{ newVariable ? $locale.baseText('variable.interactionMenu__buttonToRegisterVariable') : $locale.baseText('variable.modal__EditHeader') }}
				</span>
				<div @click="showAlert = true" class="modal__headerIcon" ></div>
			</template>
			<template #body>
				<div class="createVariable__bodySecondSectionSelects" v-click-outside="disableInput">
					<button
						class="createVariable__bodySecondSectionSelect"
						:class="{ blockedSelect: newVariable}"
						@click="activeSearch()"
						@keyup.esc="showSelect = !showSelect"
						v-if="!showSelect"
					>
						<div>{{ workflowSelected?.nome }}</div>
						<div class="createVariable__bodySecondSectionSelectOptionsProject">{{ workflowSelected.projeto.nome }}</div>
					</button>
					<AliareInput
						className="input-custom--createVariable"
						labelText="Fluxos e seu Projeto"
						v-model="queryInput"
						@input="searchInfoByName()"
						v-show="(showSelect && !newVariable)"
						ref="aliareInput"

					/>
					<div
						v-show="(workflowList.length && showSelect)"
						class="createVariable__bodySecondSectionSelectOptionsBackground"
					>
						<div
							class="createVariable__bodySecondSectionSelectOptions"
							v-for="info in workflowList"
							:key="info.nome"
							@click="
								(showSelect = !showSelect),
								(selectedProject = info.projeto.nome),
								getVariable(info),
								verifyVariable,
								(workflowSelected = info)
							"
						>
							<div class="createVariable__bodySecondSectionSelectOptionsFlow">
								{{ info.nome }}
							</div>
							<div class="createVariable__bodySecondSectionSelectOptionsProject">
								{{ info.projeto.nome }}
							</div>
							<div class="createVariable__bodySecondSectionLine"></div>
						</div>
					</div>
				</div>

				<Transition name="fadeHeight" mode="out-in">
					<div class="createVariable__bodySecondSectionVariable" v-if="workflowSelected">
						<div class="createVariable__bodySecondSectionVariableFirstColumn">
							<AliareInput
								labelText="Variável"
								v-model="variable"
								@input="verifyVariable"
							/>
							<div
								v-if="validation && variable"
								class="createVariable__bodySecondSectionVariableVariableSuccess"
							>
								O título da Variável está disponível para uso
							</div>
							<div
								v-if="!validation && variable"
								class="createVariable__bodySecondSectionVariableVariableError"
							>
								O Título da variável não está disponível para uso
							</div>
						</div>
						<div class="createVariable__bodySecondSectionVariableFirstColumn">
							<AliareInput
								labelText="Valor"
								v-model="variableValue"
								:maxlength="99999"
							/>
						</div>
					</div>
				</Transition>
			</template>
			<template #footer>
				<ModalButtonSuccess
					:saveIcon="true"
					@click="variablePost"
					v-if="newVariable"
					:active="!!variable && !!workflowSelected && !!selectValue && !!validation"
					titleButton="Concluir"
					width="97"
				/>
				<ModalButtonSuccess
					:saveIcon="true"
					v-if="!newVariable"
					@click="patchVariable"
					:active="!!variable && !!workflowSelected && !!selectValue && !!validation"
					:titleButton="$locale.baseText('credentialDetailing.actions.edit')"
					width="97"
				/>

				<ModalButtonCancel
					@click="showAlert = true"
					width="97"
				/>
			</template>
		</ModalAliare>

				<div class="manageVariables__body">
					<div class="container__SubBody">
						<FilterBar
							@search-applied="filterBySearch"
							@filters-applied="filterTable"
							@clearFilter="clearFilter"
							:filtering="filtering"
							:clearfiltering="clearfiltering"
							:activeFilters="filtersActive"
							:placeholder="'Filtre por variavel'"
						>
						</FilterBar>

						<div class="variableButton" v-if="!viewMode">
							<ModalButtonSuccess
								:plusIcon="true"
								:active="canAddVariable(isWorkflowActive)"
								titleButton="Adicionar Variavel Ao Fluxo"
								width="220"
								@click="(openModalNewVariable(dataModal.workflowId), notificationDelete = false)"
							/>
							<span v-if="!canAddVariable(isWorkflowActive)" class="tooltipAliare">
									{{$locale.baseText('permission.noPermissionFuncionality')}}
							</span>
						</div>
					</div>

					<Table
						class="credentials__table"
						:isLoading="isLoadingTable"
						:tableOverflow="true"
						maxheight="75vh"
						@endScroll="pagination()"
						notDisplayFooter
						:scrollInfinite="scrollInfinite"
						id="tabela"
					>
						<template v-slot:headerColumns>
							<tr class="table__tableTheadTr">
								<th class="table__tableTheadTrTh">
									<div class="table__tableTheadTrThTitle">
										<div class="table__tableTheadTrThTitleText">
											{{ $locale.baseText('projectDetailing.variableAccess') }}
										</div>
									</div>
								</th>
								<th class="table__tableTheadTrTh">
									<div class="table__tableTheadTrThTitle">
										<div class="table__tableTheadTrThTitleText">
											{{ $locale.baseText('variable.table__Values') }}
										</div>
									</div>
								</th>
								<th
									class="table__tableTheadTrTh"
									v-bind:class="{ table__tableTheadTrThLastColumn: false }"
								>
									<div class="table__tableTheadTrThTitle">
										<div class="table__tableTheadTrThTitleText">
											{{ $locale.baseText('variable.table__Actions') }}
										</div>
										<div v-bind:class="{ table__tableTheadTrThTitleFilterIcon: false }"></div>
									</div>
								</th>
							</tr>
						</template>
						<template v-slot:columns>
							<tr v-for="column in data" :key="column.id">
								<td class="break-word">{{ column.nome }}</td>
								<td class="break-word">{{ column.valor }}</td>
								<td>
									<div class="manageVariables__bodyColumn3">
										<div
											:class="{ 'manageVariables__bodyColumn_Btn1Disable': !canEditVariable(isWorkflowActive) }"
											class="manageVariables__bodyColumn_Btn1"
											@click="canEditVariable(isWorkflowActive) ? (openModalEditVariable(dataModal.workflowId, column), notificationDelete = false ): ''"
										>
											<span v-if="!canEditVariable(isWorkflowActive)" class="tooltipAliare">{{
												$locale.baseText('permission.noPermissionFuncionality')
											}}</span>
										</div>

										<div
											v-if="!viewMode"
											:class="{ 'manageVariables__bodyColumn_Btn2Disable': !canDeleteVariable(isWorkflowActive) }"
											class="manageVariables__bodyColumn_Btn2"
											@click="canDeleteVariable(isWorkflowActive) ? (notificationDelete = true, variableData = column, showAlert = true) : ''"
										>
											<span v-if="!canDeleteVariable(isWorkflowActive)" class="tooltipAliare">{{
												$locale.baseText('permission.noPermissionFuncionality')
											}}</span>
										</div>
									</div>
								</td>
							</tr>

						</template>
					</Table>
				</div>
			</template>
		</Modal>



		<AlertNotificationModal
			v-if="showAlert"
		>
			<template v-slot:icon>
				<i class="equivalenceCreateSuccessIcon"></i>
			</template>

			<template v-slot:title>
				{{ alertNotificationTitle }}
			</template>
			<template v-slot:firstText>
				<p v-html="alertNotificationDescription"></p>
			</template>

			<template v-slot:footer>
				<ModalButtonSuccess
					:titleButton="alertNotificationTitleCancel"
					width="300"
					@click="showAlert = false"
					alwaysActive
					plusIcon
				/>
				<ModalButtonCancel
					width="300"
					:loadingAction="isLoadingAction"
					:titleButton="alertNotificationTitleConfirm"
					@click="notificationDelete ? deleteVariable() : resetModal()"
				/>
			</template>
		</AlertNotificationModal>
	</div>
</template>

<script>
import ScrollInfinite from '../components/ScrollInfinite.vue';
import AliareSelect from '@/components/AliareSelect.vue';
import AliareInput from '@/components/AliareInput.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalAliare from './ModalAliare.vue';
import Modal from './Modal.vue';
import SearchBar from '@/components/SearchBar/SearchBar.vue';
import Table from './Table.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import FilterBar from '@/components/FilterBar.vue';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import TokenService from '@/services/token.service';
import Vue from 'vue';

import { mapActions } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { showMessage } from '@/components/mixins/showMessage';

import WebStorage from '@/common/WebStorage';
import { PERMISSION_IDS, MANAGER_VARIABLE_MODAL_KEY } from '@/constants';
import { workflowPermission } from '@/components/mixins/permissions/workflow';
import { variablePermission } from '@/components/mixins/permissions/variable';

export default mixins(
	restApi,
	showMessage,
	workflowPermission,
	variablePermission,
).extend({
	name: 'ManageVariables',
	components: {
		Modal,
		Table,
		FilterBar,
		SearchBar,
		ModalAliare,
		AliareSelect,
		AliareInput,
		ModalButtonSuccess,
		ModalButtonCancel,
		ScrollInfinite,
		AlertNotificationModal,
	},
	props: {
		dataModal: {
			type: Object,
		},
	},
	data() {
		return {
			MANAGER_VARIABLE_MODAL_KEY,
			modalBus: new Vue(),
			tenantId: WebStorage.getItem("tenantId"),

			widthEdit: '520px',
			height: '420px',
			showModal: false,
			showModalCancel: false,
			data: [],
			dataAccount: [],

			isLoadingTable: false,
			isSearching: false,
			searchingList: '',
			scrollInfinite: true,
			showModal: false,
			workflowSelected: '',
			variableValue: '',
			validation: false,
			variable: '',
			verifyVariableName: '',
			queryInput: '',
			workflowList: '',
			showSelect: false,
			selectedProject: '',
			newVariable: false,
			variableEdit: '',
			isLoadingAction: false,
			page: 1,
			pageSize: 10,

			dataManage: [],
			datas: [],

			showAlert: false,
			notificationDelete: false,
			variableData: [],

			filtering: false,
			filtersActive: {},
			clearfiltering: false,
		};
	},
	created() {
	},
	mounted() {
		this.getVariables();
	},
	computed: {
		viewMode() {
			return this.$route.params.mode == 'Visualizar';
		},
		workflowAtivo(){
			return this.$store.getters.workflowAtivo;
		},
		isWorkflowActive() {
			return (!!this.dataModal?.workflowData?.ativo || !!this.workflowAtivo);
		},

		alertNotificationTitle() {
			if(this.newVariable){
				return this.$locale.baseText('variable.keepCreateVariableModal.title');

			} else if(this.notificationDelete) {
				return this.$locale.baseText('variable.keepDeleteVariableModal.title');

			} else {
				return this.$locale.baseText('variable.keepEditVariableModal.title');
			}
		},
		alertNotificationDescription() {
			if(this.notificationDelete) {
				return 'Todas as parametrizações feitas na variável serão <b>excluídas</b> e você não poderá recuperá-las. O que você deseja fazer?';
			} else {
				return 'Todas as parametrizações que estão sendo feitas serão <b>perdidas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
			}
		},
		alertNotificationTitleCancel() {
			if(this.newVariable){
				return this.$locale.baseText('variable.keepCreateVariableModal.cancelButtonText');

			} else if(this.notificationDelete) {
				return this.$locale.baseText('variable.keepDeleteVariableModal.confirmButtonText');

			} else {
				return this.$locale.baseText('variable.keepEditVariableModal.cancelButtonText');
			}
		},
		alertNotificationTitleConfirm() {
			if(this.newVariable){
				return this.$locale.baseText('variable.keepCreateVariableModal.confirmButtonText');

			} else if(this.notificationDelete) {
				return this.$locale.baseText('variable.keepDeleteVariableModal.cancelButtonText');

			} else {
				return this.$locale.baseText('variable.keepEditVariableModal.confirmButtonText');
			}
		},
		workspace() {
			return this.$store.getters.workspace;
		},
  },
	methods: {
		...mapActions('variable', ['getVariableId', 'deleteVariableId', 'patchVariableId']),
		...mapActions('workflows', ['getWorkflows', 'getWorkflowsIdVariable']),
		...mapActions('aliareAccount', ['getUserAccountId', 'getPermissionFromUser']),
		...mapActions('auth', ['revokeTokens']),

		async filterBySearch(search) {
			this.filtering = search.length < 1 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length < 1 ? '' : search);
		},
		async filterTable(filters) {
			try {
				this.isLoadingTable = true;

				const payload = {
					size: 10,
					nome: filters?.filterSearch,
					id: this.dataModal.workflowId,
					workspaceId: this.workspace.id,
				};

				const dataFilter = await this.getWorkflowsIdVariable(payload);
				await this.applyFilter(dataFilter.data, payload);

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingTable = false;
			}
		},
		async applyFilter(dataFiltered, query) {
			this.filtering = true;
			this.clearfiltering = true;
			this.data = dataFiltered;

			this.$store.commit('setFilters', query);
		},

		clearFilter() {
			this.filtering = false;
			this.filtersActive = {};
			this.clearfiltering = false;

			this.$store.commit('setFilters', this.filtersActive);
			this.getVariables();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		getVariables() {
			this.isLoadingTable = true;
			const payload = {...this.$store.getters?.filters, workspaceId: this.workspace.id, id: this.dataModal.workflowId,  size: this.pageSize, page: this.page };

		 setupHeaderAliareTenant(this.tenantId);
		 this.getWorkflowsIdVariable(payload)
			.then((getData) => {
				this.data = getData.data;
				this.isLoadingTable = false;
			})
			.catch((error) => {
				console.log('error', error);
			});

		},
		async openModalNewVariable(workflow) {
			await this.getWorkflowData(workflow);
			this.showModal = true;
			this.newVariable = true;
		},
		async openModalEditVariable(workflow, variableData) {
			try {
				await this.getInitWorkflow();
				await this.getWorkflowData(workflow);
				this.variable = variableData.nome;
				this.variableValue = variableData.valor;
				this.variableEdit = variableData;
				this.showModal = true;
				this.verifyVariable();
			} catch (e) {
				console.log(e);
			}
		},
		async getWorkflowData(workflow) {
			try {
				this.workflowSelected = await this.restApi().getWorkflow(this.workspace.id, workflow);
				this.getVariable(this.workflowSelected);
				this.selectValue = this.workflowSelected.tenant.id;
			} catch (e) {
				console.log(e);
			}
		},
		getVariable(data) {
			this.verifyVariableName = [];
			this.getWorkflowsIdVariable({workspaceId: this.workspace.id, id: data.id, page: this.page, size: this.pageSize})
				.then((response) => {
					this.verifyVariableName = response.data;
				})
				.catch((error) => {
					console.log('error', error);
				});
		},
		async resetModal() {
			this.showAlert = false;
			this.showModalCancel = false;
			this.showModal = false;
			this.workflowSelected = '';
			this.selectValue = '';
			this.selectedProject = '',
			this.variableValue = '',
			this.validation = false,
			this.variable = '',
			this.variableEdit = '',
			this.verifyVariableName = '',
			this.queryInput = '',
			this.showSelect = false,
			this.newVariable = false
		},
		async variablePost() {
			if (this.selectValue) {
				setupHeaderAliareTenant(this.selectValue);

				try {
					await this.restApi().createNewVariable(this.workspace.id, {
						workflowId: this.workflowSelected.id,
						nome: this.variable,
						valor: this.variableValue,
					});

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('variable.successMessageCreateVariable', { interpolate: { savedVariableName: this.variable, savedWorkflowName: this.workflowSelected.nome, savedProjectName: this.workflowSelected.projeto.nome }}),
						status: 'success',
					});
					this.page = 1;
					this.getVariables();
					this.resetModal(false);
				} catch(e) {
					this.$store.commit('activeAlert', {
						message: `Erro ao criar variável. Tente Novamente.`,
						status: 'error',
					});
				};
			}
		},
		disableInput() {
			this.showSelect = false;
		},
		activeSearch() {
			if (this.newVariable) return;

			this.showSelect = !this.showSelect;
			this.$nextTick(() => {
				this.$refs.aliareInput.$refs.input.focus();
			});
		},
		async searchInfoByName() {
			if (this.queryInput.length > 2) {
				try {
					this.workflowList = await this.restApi().getWorkflows(this.workspace.id, {
						nome: this.queryInput,
						size: 5,
					});
				} catch (e) {
					console.log(e);
				}
			} else {
				// Limpar o data quando menor que 2 caracteres no input
				this.workflowList = [];
				this.getInitWorkflow();
			}
		},
		async getInitWorkflow() {
			if(this.queryInput.length === 0) {
				this.showSelect = false;
				try {
						this.workflowList = await this.restApi().getWorkflows(this.workspace.id, {
							size: 5,
						});

						await this.workflowList.sort(function(x,y) {
							let a = x.nome.toUpperCase(),
									b = y.nome.toUpperCase();
							return a == b ? 0 : a> b ? 1 : -1;
						});
				} catch (e) {
						console.log(e);
				}
			}
		},
		verifyVariable() {
			if (this.verifyVariableName.length === 0 || this.variable === this.variableEdit.nome) {
				this.validation = true;
			} else {
				for (const index in this.verifyVariableName) {
					if (this.variable === this.verifyVariableName[index].nome) {
						this.validation = false;
						break;
					} else {
						this.validation = true;
					}
				}
			}
		},
		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},
		async patchVariable() {
			let workflowOpen = this.variableEdit.workflowId;
			const payload = {
				id: this.variableEdit.id,
				workflowId: this.workflowSelected.id,
				nome: this.variable,
				valor: this.variableValue,
			};
			if (this.selectValue) {
				setupHeaderAliareTenant(this.selectValue);
				try {
					await this.restApi().updateVariable(this.workspace.id, payload);

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('variable.successMessageEditVariable', { interpolate: { savedVariableName: this.variable, savedWorkflowName: this.workflowSelected.nome, savedProjectName: this.workflowSelected.projeto.nome }}),
						status: 'success',
					});
					this.page = 1;
					this.resetModal(false);
					this.getVariables();
				} catch (e) {
					this.$store.commit('activeAlert', {
						message: 'Erro ao alterar variável. Tente Novamente.',
						status: 'error',
					});
				};
			};
		},
		async deleteVariable() {
			this.isLoadingAction = true;
			await this.deleteVariableId({workspaceId: this.workspace.id, variableId: this.variableData.id})
				.then(() => {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('variable.successDeleteVariable', { interpolate: { deleteVariableName: this.variableData.nome}}),
						status: 'success',
					});
					this.showAlert = false;
					this.page = 1;
					this.getVariables();
				})

				.catch((error) => {
					this.$store.commit('activeAlert', {
						message: 'Erro ao excluir Variável',
						status: 'error',
					});
				});
			
			this.isLoadingAction = false;
		},
		pagination() {
			if (this.data?.length % this.pageSize === 0) {
				this.page++;
				const payload = {workspaceId: this.workspace.id, id: this.dataModal.workflowId, size: this.pageSize, page: this.page };

				this.getWorkflowsIdVariable(payload)
					.then((response) => {
						for (const index in response.data) {
							this.data.push(response.data[index]);
						}
					})
					.catch((error) => {
						this.$store.commit('activeAlert', {
							message: error.response?.data?.error_description || error.message || error.toString(),
							status: 'error',
						});
					});
			} else {
				this.scrollInfinite = false;
			}
		},
	},
	beforeDestroy() {
		const empty = 'empty';
    this.$store.commit('setOpenRouteName', empty);
		this.$store.commit('setFilters', {});
  },
});
</script>

<style lang="scss" scoped>

.credentials__table {
	padding: 0 1.5%;
	margin-bottom: 1.5%;
	position: relative;
}
.manageVariables {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	.manageVariablesIcon {
		background-image: url('../assets/closeIcon.svg');
		background-position: center;
		background-repeat: no-repeat;
		height: 32px;
		width: 32px;
		cursor: pointer;
		border-radius: 3px;
	}
	.manageVariablesIcon:hover {
		border: 1px solid #4a638f;
	}
}
.manageVariables__body {
	width: 100%;
	border: 1px solid #4a638f;
	padding: inherit;
	border-radius: 4px;

	.manageVariables__bodyColumn3 {
		display: flex;
		align-items: center;
		justify-content: center;
		.manageVariables__bodyColumn_Btn1 {
			background-color: #1c2638;
			border: 1px solid #4a638f;
			border-radius: 4px 0px 0px 4px;
			width: 32px;
			height: 32px;
			background-image: url('../assets/pen-blue.svg');
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.manageVariables__bodyColumn_Btn1:hover {
			background-color: #29364f;
			border: 1px solid #00c3dd;
		}
		.manageVariables__bodyColumn_Btn1Disable {
			background-color: #1c2638;
			border: 1px solid #4a638f;
			border-radius: 4px 0px 0px 4px;
			width: 32px;
			height: 32px;
			background-image: url('../assets/pen.svg');
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;

			&:hover {
				background-color: #29364f;
				position: sticky;

				.tooltipAliare {
					display: flex;
				}
			}
		}
		.manageVariables__bodyColumn_Btn2 {
			background-color: #1c2638;
			border: 1px solid #4a638f;
			border-radius: 0px 4px 4px 0px;
			width: 32px;
			height: 32px;
			background-image: url('../assets/delete.svg');
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.manageVariables__bodyColumn_Btn2:hover {
			background-color: #29364f;
			border: 1px solid #ff495f;
		}
		.manageVariables__bodyColumn_Btn2Disable {
			background-color: #1c2638;
			border: 1px solid #4a638f;
			border-radius: 0px 4px 4px 0px;
			width: 32px;
			height: 32px;
			background-image: url('../assets/deleteGrey.svg');
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;

			&:hover {
				background-color: #29364f;
				position: sticky;

				.tooltipAliare {
					display: flex;
				}
			}
		}
	}
}

.modal__headerIcon {
	width: 14px;
	height: 14px;
	background-image: url('./../assets/closeIcon.svg');
	cursor: pointer;
}

.createVariable__bodySecondSectionVariable {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
}

.createVariable__bodySecondSectionSelects, .createVariable__bodySecondSectionVariable {
	margin-bottom: 10px;
	margin-top: 24px;
	width: 100%;

	.createVariable__bodySecondSectionVariableVariableSuccess {
		color: #59d64c;
		padding-left: 8px;
		font-size: 12px;
	}
	.createVariable__bodySecondSectionVariableVariableError {
		color: #ff495f;
		padding-left: 8px;
		font-size: 12px;
	}
}

.createVariable__bodySecondSectionSelectOptionsBackground {
	margin-top: 8px;
	max-height: 25vh;
	overflow-y: scroll;
	z-index: 9;
	position: absolute;
	background-color: #223049;
	border: 1px solid #4a638f;
	border-radius: 4px;
	width: calc(100% - 20px);
	color: rgba(255, 255, 255, 0.88);
	padding: 16px;
	.createVariable__bodySecondSectionSelectOptions {
		width: 100%;
		cursor: pointer;

		.createVariable__bodySecondSectionSelectOptionsFlow {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: rgba(255, 255, 255, 0.88);
		}
	}
}
.createVariable__bodySecondSectionSelect {
	background-color: #223049;
	border: 1px solid #4a638f;
	border-radius: 4px;
	line-height: 1;
	width: 100%;
	display: flex;
	height: 58px;
	justify-content: center;
	flex-direction: column;
	row-gap: 4px;
	color: rgba(255, 255, 255, 0.88);
	padding: 11px 9px;

	&.blockedSelect {
		cursor: default;
	}
}

.container__SubBody {
	display: flex;
	margin-bottom: 20px;
	justify-content: space-between;
}

.variableButton {
	display: flex;
	margin: 0 0 0 10px;

	&:hover {
		position: relative;

		.tooltipAliare {
			display: flex;
			z-index: 10;
		}
	}
}

.modal__headerCancel {
	display: flex;
	.modal__cancelIcon {
		background-image: url('./../assets/warningIconYellow.svg');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		width: 22px;
		height: 19px;
		margin-right: 12px;
	}
	.modal__cancelTitle {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #ffa53b;
	}
}
.modal__cancelBody {
	padding: 0px 0px 13px 34px;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: rgba(255, 255, 255, 0.88);
}

.buttons_modal {
	width: 220px;
	height: 40px;
	background-color: #141722;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
	border-radius: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
	justify-content: center;

	&--continue {
		display: flex;
		flex-direction: column;
		color: #00c3dd;
		border: 1px solid #00c3dd;
		margin-bottom: 20px;

		&:hover {
			background-color: #00c3dd;
			color: #000000;
		}
	}

	&--cancel {
		border: 1px solid #ff495f;
		color: #ff495f;

		&:hover {
			background-color: #ff495f;
			color: #000000;
		}
	}
}

:deep {
	.labelInput {
    padding: 0 3px !important;
    left: 6px !important;
	}
	.searchBar{
		width: 100%;

		&--input {
			width:100%;
		}
	}

	.modal-mask {
		z-index: 2003;
	}

	.modal-body {
		padding: 0px 10px;
	}

	.input-custom--createVariable input {
    height: 58px;
	}

	.modal-content {
    padding: 17px 17px 10px;
	}

	.modal-container .modal-footer {
		padding: 30px 20px 20px 0px;
	}

	.modal-container {
		border-radius: 4px;
		box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.08), 2px 2px 4px rgba(0, 0, 0, 0.24);
		border: 1px solid #34476a;
		display: flex;
		flex-direction: column;
	}

	.filter-bar {
    margin: 0 0px 10px 0px;
		width: 76%;
	}

}
</style>
<style lang="scss" scoped>
.v-modal {
	z-index: 15 !important;
}
</style>
