
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';
import { FILTER_PANEL_MODAL_KEY } from '../constants';
import { showMessage } from '@/components/mixins/showMessage';
import { searchPermissions, removeItemFromArrayByValue } from '@/components/helpers';

import TimePickerAliare from '@/components/TimePickerAliare.vue';
import Modal from './Modal.vue';
import InfoBox from '@/components/InfoBox.vue';
import SelectTree from '@/components/SelectTree.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AliareInput from '@/components/AliareInput.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import SwitchAliare from '@/components/SwitchAliare.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import { workflowPermission } from '@/components/mixins/permissions/workflow';
import WebStorage from '@/common/WebStorage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { mapActions } from 'vuex';

import moment from 'moment';

export default mixins(restApi, showMessage, workflowHelpers, workflowPermission).extend({
	name: 'FilterPanelModal',
	components: {
		Modal,
		TimePickerAliare,
		InfoBox,
		SelectTree,
		SwitchAliare,
		ModalButtonCancel,
		AliareInput,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
		},
	},
	data() {
		return {
			changeOnFilter: false,
			isLoading: false,
			showAlert: {
				mode: '',
				active: false,
			},
			form: {
				date: {
					dateString: this.$store.getters.dataFilterPanel.date.dateString,
					dateStart: this.$store.getters.dataFilterPanel.date.dateStart,
					dateEnd: this.$store.getters.dataFilterPanel.date.dateEnd,
					intervalStart: '',
					intervalStartTime: '00:00',
					intervalEnd: '',
					intervalEndTime: '23:59',
				},
				selectedOrganizationIds: this.$store.getters.dataFilterPanel.selectedOrganizationIds[0] == 'null' ? [] : [...this.$store.getters.dataFilterPanel.selectedOrganizationIds],
				selectedWorkspaceIds: this.$store.getters.dataFilterPanel.selectedWorkspaceIds[0] == 'null' ? [] : [...this.$store.getters.dataFilterPanel.selectedWorkspaceIds],
				selectedProjectIds: this.$store.getters.dataFilterPanel.selectedProjectIds[0] == 'null' ? [] : [...this.$store.getters.dataFilterPanel.selectedProjectIds],
				selectedFlowIds: this.$store.getters.dataFilterPanel.selectedFlowIds[0] == 'null' ? [] : [...this.$store.getters.dataFilterPanel.selectedFlowIds],
				resultExecutionFilter: this.$store.getters.dataFilterPanel.resultExecutionFilter[0] == 'null' ? [] : [...this.$store.getters.dataFilterPanel.resultExecutionFilter],
			},
			organizationSearch: '',
			workspaceSearch: '',
			projectSearch: '',
			flowSearch: '',
			searchResultExecution: '',
			showOrganizationOptions: false,
			showWorkspaceOptions: false,
			showProjectOptions: false,
			showWorkflowOptions: false,
			isTenantSelected: false,
			firstSelectionType: null,
			isLoadingFilters: false,

			showResultExecutionOptions: false,
			tokenData: WebStorage.getItem('tokenData'),
			organizations: [],
			modeDate: '',
			modalBus: new Vue(),
			FILTER_PANEL_MODAL_KEY,
			filterData: {
				organizacoes: [],
			},
			
			filterResultOptions: [
				'Sucesso',
				'Falha',
				'Alerta',
				'Interrompido',
				'Não Armazenado',
			],
		};
	},
	async created() {
		this.isLoading = true;
		
		if (this.isManage) {
			await this.getOrganizationsToFilter()
			if (!!this.form.selectedOrganizationIds.length) {
				await this.getFilterOptions();
			}
		} else {
			await this.getFilterOptions();
		}

		this.isLoading = false;
	},
	computed: {
		canApplyFilters() {
			if (this.modeDate == 'interval') {
				return this.form.date.intervalStart != '' && this.form.date.intervalEnd != '';
			}
			return true;
		},
		singleTenant() {
			return this.filterData.organizacoes.length <= 1;
		},
		organizationId() {
			return WebStorage.getItem('tenantId');
		},
		displayedOrganizations() {
			if (this.isFirstSelectionEmpty()) {
				return this.filterBySearch(this.filterData.organizacoes, 'organization');
			} else {
				return this.filterOrganizationsBasedOnLowestSelection();
			}
		},
		displayedWorkspaces() {
			if (this.isFirstSelectionEmpty()) {
				return this.filterBySearch(
					this.filterData.organizacoes.reduce((acc, org) => acc.concat(org.workspaces), []),
					'workspace',
				);
			} else {
				return this.filterWorkspacesBasedOnLowestSelection();
			}
		},
		displayedProjects() {
			if (this.isFirstSelectionEmpty()) {
				return this.filterBySearch(
					this.filterData.organizacoes.reduce((acc, org) => {
						org.workspaces.forEach((ws) => {
							if (ws?.projetos) {
								acc.push(...ws.projetos);
							}
						});
						return acc;
					}, []),
					'project',
				);
			} else {
				return this.filterProjectsBasedOnLowestSelection();
			}
		},
		displayedFlows() {
			if (this.isFirstSelectionEmpty()) {
				return this.filterBySearch(
					this.filterData.organizacoes.reduce((acc, org) => {
						org.workspaces.forEach((ws) => {
							ws?.projetos?.forEach((proj) => {
								proj?.workflows?.forEach((workflow) => {
									acc.push(workflow);
								});
							});
						});
						return acc;
					}, []),
					'flow',
				);
			} else {
				return this.filterFlowsBasedOnLowestSelection();
			}
		},

		resultExecutionFiltered() {
			if (!this.searchResultExecution) return this.filterResultOptions;

			return this.filterResultOptions.filter((filter) =>
				filter?.toLowerCase().includes(this.searchResultExecution.toLowerCase()),
			);
		},
		selectedOrganizationNames() {
			return this.organizations
				.filter((org) => this.form.selectedOrganizationIds.includes(org.id))
				.map((org) => org.nome);
		},
		selectedWorkspaceNames() {
			return this.displayedWorkspaces
				.filter((ws) => this.form.selectedWorkspaceIds.includes(ws.id))
				.map((ws) => ws.nome);
		},
		selectedProjectNames() {
			return this.displayedProjects
				.filter((proj) => this.form.selectedProjectIds.includes(proj.id))
				.map((proj) => proj.nome);
		},
		selectedFlowNames() {
			return this.displayedFlows
				.filter((flow) => this.form.selectedFlowIds.includes(flow.id))
				.map((flow) => flow.nome);
		},

		todayText() {
			const localDate = moment().local().format('DD [de] MMMM [de] YYYY');
			return `Hoje, ${localDate}`;
		},
		lastSevenText() {
			const localDate = moment().local().format('DD/MM/YYYY');
			const substractDate = moment().local().subtract(7, 'days').format('DD/MM/YYYY');
			return `Últimos 7 dias, de ${substractDate} até ${localDate}`;
		},
		lastThirtyDaysText() {
			const localDate = moment().local().format('DD/MM/YYYY');
			const substractDate = moment().local().subtract(30, 'days').format('DD/MM/YYYY');
			return `Últimos 30 dias, de ${substractDate} até ${localDate}`;
		},
		lastSixtyDaysText() {
			const localDate = moment().local().format('DD/MM/YYYY');
			const substractDate = moment().local().subtract(60, 'days').format('DD/MM/YYYY');
			return `Últimos 60 dias, de ${substractDate} até ${localDate}`;
		},
		keepText() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return 'Manter Fluxo de Falha';
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Manter parametrização';
			}
		},

		cancelText() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return this.$locale.baseText('workflowDetails.patchFlowFail.removeFailureFlow');
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Cancelar parametrização';
			}
		},

		allResultExecutionSelected() {
			if (this.filterResultOptions?.length == this.form.resultExecutionFilter?.length) return true;
			return false;
		},

		partialResultExecutionSelected() {
			return (
				this.filterResultOptions?.length != this.form.resultExecutionFilter?.length &&
				this.form.resultExecutionFilter?.length > 0
			);
		},

		alertNotificationDescription() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return this.$locale.baseText(
					`Ao parar de usar o <b>Fluxo de Falha</b>, caso a execução do fluxo que você está criando resulte em <b>Falha</b>, não haverá o acionamento de um <b>Fluxo de Falha</b>. O que deseja fazer?`,
				);
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Todas as parametrizações que estão sendo feitas serão excluídas e você não poderá recuperá-las. O que você deseja fazer?';
			}
		},
		pageTitleMode() {
			return 'Opções de Filtros';
		},
		workflowId(): number {
			return this.$store.getters.workflowId;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
		isManage() {
			if (this.$route.name === 'adminIntegrationPanel') return true;

			return false;
		},
		hasFilterApplyed() {
			return (
				this.$store.getters.dataFilterPanel.selectedOrganizationIds[0] !== 'null' ||
				this.$store.getters.dataFilterPanel.selectedWorkspaceIds[0] !== 'null' ||
				this.$store.getters.dataFilterPanel.selectedProjectIds[0] !== 'null' ||
				this.$store.getters.dataFilterPanel.selectedFlowIds[0] !== 'null'
			);
		},
	},
	watch: {
		form: {
			handler(newVal, oldVal) {
				this.changeOnFilter = true;
			},
			deep: true
		}
	},
	methods: {
		...mapActions('aliareAccount', ['getAccountTenantV2']),
		translateStatus() {
			const traducoes = {
				"Falha": "error",
				"Sucesso": "success",
				"Alerta": "alert",
				"Não Armazenado": "unknown/crashed",
				"Interrompido": "canceled"
			};

			return this.form.resultExecutionFilter.map(status => traducoes[status] || ['null']);
		},
		resetFilter() {
			this.form = {
				date: {
					dateString: 'today',
					dateStart: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
					dateEnd: moment().endOf('day').format('YYYY-MM-DD HH:mm'),
				},
				selectedOrganizationIds: [],
				selectedWorkspaceIds: [],
				selectedProjectIds: [],
				selectedFlowIds: [],
				resultExecutionFilter: [],
				translateResultExecutionFilter: [],
			}
		},
		setFirstSelection(type) {
			if (!this.firstSelectionType) {
				this.firstSelectionType = type;
			}
		},
		async getOrganizationsToFilter() {
			const response = await searchPermissions(this.tokenData.sub, this.tokenData.Tenant);

			if (this.tokenData.Level === 'N1' || this.tokenData.Level === 'N0' && response) {
				const payload = { pageSize: 999 };

				const response = await this.getAccountTenantV2(payload);
				const filteredData = response.data.filter(account => account.active && !account.blocked);
				filteredData.forEach((organization) => {
					this.organizations.push({nome: organization.description, id: organization.id})
				})
				return response;
			}
		},
		toggleSelection(type, id) {
			this.setFirstSelection(type);
			switch (type) {
				case 'organization':
					this.form.selectedOrganizationIds = this.toggleArrayItem(this.form.selectedOrganizationIds, id);
					break;
				case 'workspace':
					this.form.selectedWorkspaceIds = this.toggleArrayItem(this.form.selectedWorkspaceIds, id);
					break;
				case 'project':
					this.form.selectedProjectIds = this.toggleArrayItem(this.form.selectedProjectIds, id);
					break;
				case 'flow':
					this.form.selectedFlowIds = this.toggleArrayItem(this.form.selectedFlowIds, id);
					break;
			}
			if (this.isFirstSelectionEmpty()) {
				this.firstSelectionType = null;
			}
		},
		toggleArrayItem(array, item) {
			const index = array.indexOf(item);
			if (index > -1) {
				return array.filter((i) => i !== item);
			} else {
				return [...array, item];
			}
		},
		toggleResultExecution(status) {
			if (this.verifyResultExecutionSelected(status)) {
				removeItemFromArrayByValue(this.form.resultExecutionFilter, status);
			} else {
				this.form.resultExecutionFilter.push(status);
			}
		},
		isFirstSelectionEmpty() {
			return (
				this.form.selectedOrganizationIds.length === 0 &&
				this.form.selectedWorkspaceIds.length === 0 &&
				this.form.selectedProjectIds.length === 0 &&
				this.form.selectedFlowIds.length === 0
			);
		},
		filterBySearch(items, type) {
			const searchTerm = this[`${type}Search`].toLowerCase();
			return items.filter((item) => item.nome.toLowerCase().includes(searchTerm));
		},
		filterOrganizationsBasedOnLowestSelection() {
			if (this.form.selectedFlowIds.length > 0) {
				return this.filterOrganizationsByFlows();
			} else if (this.form.selectedProjectIds.length > 0) {
				return this.filterOrganizationsByProjects();
			} else if (this.form.selectedWorkspaceIds.length > 0) {
				return this.filterOrganizationsByWorkspaces();
			}
			return this.filterBySearch(this.filterData.organizacoes, 'organization');
		},
		filterOrganizationsByWorkspaces() {
			const selectedWorkspaceOrgs = this.filterData.organizacoes.filter((org) =>
				org.workspaces.some((ws) => this.form.selectedWorkspaceIds.includes(ws.id)),
			);
			return this.filterBySearch(selectedWorkspaceOrgs, 'organization');
		},
		filterOrganizationsByProjects() {
			const selectedProjectOrgs = this.filterData.organizacoes.filter((org) =>
				org.workspaces.some((ws) =>
					ws.projetos.some((proj) => this.form.selectedProjectIds.includes(proj.id)),
				),
			);
			return this.filterBySearch(selectedProjectOrgs, 'organization');
		},
		filterOrganizationsByFlows() {
			const selectedFlowOrgs = this.filterData.organizacoes.filter((org) =>
				org.workspaces.some((ws) =>
					ws.projetos.some(
						(proj) => proj.workflows?.some((flow) => this.form.selectedFlowIds.includes(flow.id)),
					),
				),
			);
			return this.filterBySearch(selectedFlowOrgs, 'organization');
		},
		filterWorkspacesBasedOnLowestSelection() {
			if (this.form.selectedFlowIds.length > 0) {
				return this.filterWorkspacesByFlows();
			} else if (this.form.selectedProjectIds.length > 0) {
				return this.filterWorkspacesByProjects();
			} else if (this.form.selectedOrganizationIds.length > 0) {
				return this.filterWorkspacesByOrganizations();
			}
			return this.filterBySearch(
				this.filterData.organizacoes.reduce((acc, org) => acc.concat(org.workspaces), []),
				'workspace',
			);
		},
		filterWorkspacesByOrganizations() {
			const selectedOrganizationWorkspaces = this.filterData.organizacoes
				.filter((org) => this.form.selectedOrganizationIds.includes(org.id))
				.reduce((acc, org) => acc.concat(org.workspaces), []);
			return this.filterBySearch(selectedOrganizationWorkspaces, 'workspace');
		},
		filterWorkspacesByProjects() {
			const selectedProjectWorkspaces = this.filterData.organizacoes.reduce((acc, org) => {
				org.workspaces.forEach((ws) => {
					if (
						ws.projetos.some((proj) => this.form.selectedProjectIds.includes(proj.id)) &&
						!acc.some((existingWs) => existingWs.id === ws.id)
					) {
						acc.push(ws);
					}
					if (
						this.form.selectedWorkspaceIds.includes(ws.id) &&
						!acc.some((existingWs) => existingWs.id === ws.id)
					) {
						acc.push(ws);
					}
				});
				return acc;
			}, []);
			return this.filterBySearch(selectedProjectWorkspaces, 'workspace');
		},

		filterWorkspacesByFlows() {
			const selectedFlowWorkspaces = this.filterData.organizacoes.reduce((acc, org) => {
				org.workspaces.forEach((ws) => {
					if (
						ws.projetos.some((proj) =>
							proj.workflows?.some((flow) => this.form.selectedFlowIds.includes(flow.id)),
						) &&
						!acc.some((existingWs) => existingWs.id === ws.id)
					) {
						acc.push(ws);
					}
					if (
						this.form.selectedWorkspaceIds.includes(ws.id) &&
						!acc.some((existingWs) => existingWs.id === ws.id)
					) {
						acc.push(ws);
					}
				});
				return acc;
			}, []);
			return this.filterBySearch(selectedFlowWorkspaces, 'workspace');
		},
		filterProjectsBasedOnLowestSelection() {
			if (this.form.selectedFlowIds.length > 0) {
				return this.filterProjectsByFlows();
			} else if (this.form.selectedWorkspaceIds.length > 0) {
				return this.filterProjectsByWorkspaces();
			} else if (this.form.selectedOrganizationIds.length > 0) {
				return this.filterProjectsByOrganizations();
			}
			return this.filterBySearch(
				this.filterData.organizacoes.reduce((acc, org) => {
					org.workspaces.forEach((ws) => acc.push(...ws.projetos));
					return acc;
				}, []),
				'project',
			);
		},
		filterProjectsByOrganizations() {
			const selectedOrganizationProjects = this.filterData.organizacoes
				.filter((org) => this.form.selectedOrganizationIds.includes(org.id))
				.reduce((acc, org) => {
					org.workspaces.forEach((ws) => acc.push(...ws.projetos));
					return acc;
				}, []);
			return this.filterBySearch(selectedOrganizationProjects, 'project');
		},
		filterProjectsByWorkspaces() {
			const selectedWorkspaceProjects = this.filterData.organizacoes.reduce((acc, org) => {
				org.workspaces.forEach((ws) => {
					if (this.form.selectedWorkspaceIds.includes(ws.id)) {
						acc.push(...ws.projetos);
					}
				});
				return acc;
			}, []);
			return this.filterBySearch(selectedWorkspaceProjects, 'project');
		},
		filterProjectsByFlows() {
			const selectedFlowProjects = this.filterData.organizacoes.reduce((acc, org) => {
				org.workspaces.forEach((ws) => {
					ws.projetos.forEach((proj) => {
						if (
							proj.workflows?.some((flow) => this.form.selectedFlowIds.includes(flow.id)) &&
							!acc.some((existingProj) => existingProj.id === proj.id)
						) {
							acc.push(proj);
						}
						if (
							this.form.selectedProjectIds.includes(proj.id) &&
							!acc.some((existingProj) => existingProj.id === proj.id)
						) {
							acc.push(proj);
						}
					});
				});
				return acc;
			}, []);
			return this.filterBySearch(selectedFlowProjects, 'project');
		},

		filterFlowsBasedOnLowestSelection() {
			if (this.form.selectedProjectIds.length > 0) {
				return this.filterFlowsByProjects();
			} else if (this.form.selectedWorkspaceIds.length > 0) {
				return this.filterFlowsByWorkspaces();
			} else if (this.form.selectedOrganizationIds.length > 0) {
				return this.filterFlowsByOrganizations();
			}
			return this.filterBySearch(
				this.filterData.organizacoes.reduce((acc, org) => {
					org.workspaces.forEach((ws) =>
						ws.projetos.forEach((proj) => {
							if (proj.workflows) {
								acc.push(...proj.workflows);
							}
						}),
					);
					return acc;
				}, []),
				'flow',
			);
		},
		filterFlowsByOrganizations() {
			const selectedOrganizationFlows = this.filterData.organizacoes
				.filter((org) => this.form.selectedOrganizationIds.includes(org.id))
				.reduce((acc, org) => {
					org.workspaces.forEach((ws) =>
						ws.projetos.forEach((proj) => {
							if (proj.workflows) {
								acc.push(...proj.workflows);
							}
						}),
					);
					return acc;
				}, []);
			return this.filterBySearch(selectedOrganizationFlows, 'flow');
		},
		filterFlowsByWorkspaces() {
			const selectedWorkspaceFlows = this.filterData.organizacoes.reduce((acc, org) => {
				org.workspaces.forEach((ws) => {
					if (this.form.selectedWorkspaceIds.includes(ws.id)) {
						ws.projetos.forEach((proj) => {
							if (proj.workflows) {
								proj.workflows.forEach((flow) => {
									if (!acc.some((existingFlow) => existingFlow.id === flow.id)) {
										acc.push(flow);
									}
								});
							}
						});
					}
					ws.projetos.forEach((proj) => {
						proj.workflows?.forEach((flow) => {
							if (
								this.form.selectedFlowIds.includes(flow.id) &&
								!acc.some((existingFlow) => existingFlow.id === flow.id)
							) {
								acc.push(flow);
							}
						});
					});
				});
				return acc;
			}, []);
			return this.filterBySearch(selectedWorkspaceFlows, 'flow');
		},
		filterFlowsByProjects() {
			const selectedProjectFlows = this.filterData.organizacoes.reduce((acc, org) => {
				org.workspaces.forEach((ws) => {
					ws.projetos.forEach((proj) => {
						if (this.form.selectedProjectIds.includes(proj.id)) {
							proj.workflows?.forEach((flow) => {
								if (!acc.some((existingFlow) => existingFlow.id === flow.id)) {
									acc.push(flow);
								}
							});
						}
						proj.workflows?.forEach((flow) => {
							if (
								this.form.selectedFlowIds.includes(flow.id) &&
								!acc.some((existingFlow) => existingFlow.id === flow.id)
							) {
								acc.push(flow);
							}
						});
					});
				});
				return acc;
			}, []);
			return this.filterBySearch(selectedProjectFlows, 'flow');
		},

		selectAllResultExecution() {
			this.filterResultOptions.forEach((filterResult) => {
				if (this.verifyResultExecutionSelected(filterResult)) return;

				this.form.resultExecutionFilter.push(filterResult);
			});
		},

		deselectAllResultExecution() {
			this.filterResultOptions.forEach((filterResult) => {
				removeItemFromArrayByValue(this.form.resultExecutionFilter, filterResult);
			});
		},

		verifyResultExecutionSelected(status) {
			return this.form.resultExecutionFilter.find((filterSelected) => filterSelected === status);
		},
		resetInputOrganization() {
			this.showOrganizationOptions = false;
			this.organizationSearch = '';
		},
		resetInputWorskpace() {
			this.showWorkspaceOptions = false;
			this.organizationSearch = '';
		},
		resetInputProject() {
			this.showProjectOptions = false;
			this.projectSearch = '';
		},
		resetInputWorkflow() {
			this.showWorkflowOptions = false;
			this.flowSearch = '';
		},
		resetInputResultExecution() {
			this.showResultExecutionOptions = false;
		},
		async getFilterOptions(resetData) {
			try {
				this.isLoadingFilters = true;
				this.filterData = '';
				if ((this.isManage && !this.hasFilterApplyed) || resetData) {
					this.isTenantSelected = false;
					this.form.selectedWorkspaceIds = [];
					this.form.selectedProjectIds = [];
					this.form.selectedFlowIds = [];
				}
				const payload = this.isManage ? this.form.selectedOrganizationIds : [this.organizationId]
				const response = await this.restApi().getFilterOptionsPanelIntegration(payload);
				this.isTenantSelected = true;
				this.isLoadingFilters = false;
				this.filterData = response;
			} catch (e) {
				console.error(e);
			}
		},
		updateTimeStart(newValue) {
			this.form.date.intervalStartTime = newValue;

			this.changeValueStart();
		},
		updateTimeEnd(newValue) {
			this.form.date.intervalEndTime = newValue;

			this.changeValueEnd();
		},
		changeValueEnd() {
			const momentStart = moment(this.form.date.intervalStart);
			const momentEnd = moment(this.form.date.intervalEnd);
			const momentNow = moment().local().utc();

			momentStart.add(this.form.date.intervalStartTime.slice(0, -3), 'hours');
			momentStart.add(this.form.date.intervalStartTime.slice(3), 'minutes');

			momentEnd.add(this.form.date.intervalEndTime.slice(0, -3), 'hours');
			momentEnd.add(this.form.date.intervalEndTime.slice(3), 'minutes');


			if (momentEnd.isAfter(momentNow)) {
				this.form.date.intervalEnd = '';
				this.form.date.intervalEndTime = '00:00';
				this.$store.commit('activeAlert', {
					message: 'A data final não pode ser maior que o dia atual!',
					status: 'error',
				});
				return;
			}

			if (this.form.date.intervalStart == '') return;

			if (momentStart.isAfter(momentEnd)) {
				this.form.date.intervalEnd = '';
				this.form.date.intervalEndTime = '00:00';

				this.$store.commit('activeAlert', {
					message: 'A data final não pode ser menor que a data inicial!',
					status: 'error',
				});
				return;
			} else if (momentEnd.diff(momentStart, 'days') > 60) {
				this.form.date.intervalEnd = '';
				this.form.date.intervalEndTime = '00:00';

				this.$store.commit('activeAlert', {
					message: 'O intervalo entre as datas não pode ultrapassar os 60 dias',
					status: 'error',
				});
				return;
			}
		},
		changeValueStart() {
			const momentStart = moment(this.form.date.intervalStart);
			const momentEnd = moment(this.form.date.intervalEnd);
			const momentNow = moment().local().utc();

			momentStart.add(this.form.date.intervalStartTime.slice(0, -3), 'hours');
			momentStart.add(this.form.date.intervalStartTime.slice(3), 'minutes');

			momentEnd.add(this.form.date.intervalEndTime.slice(0, -3), 'hours');
			momentEnd.add(this.form.date.intervalEndTime.slice(3), 'minutes');

			if (momentStart.isAfter(momentNow)) {
				this.form.date.intervalStartTime = '00:00';
				this.form.date.intervalStart = '';
				this.$store.commit('activeAlert', {
					message: 'A data inicial não pode ser maior que o dia atual!',
					status: 'error',
				});
				return;
			}

			if (this.form.date.intervalEnd == '') return;

			if (momentStart.isAfter(momentEnd)) {
				this.form.date.intervalStartTime = '00:00';
				this.form.date.intervalStart = '';
				this.$store.commit('activeAlert', {
					message: 'A data inicial não pode ser maior que a data final!',
					status: 'error',
				});
				return;
			} else if (momentEnd.diff(momentStart, 'days') > 60) {
				this.form.date.intervalStart = '';
				this.form.date.intervalStartTime = '00:00';

				this.$store.commit('activeAlert', {
					message: 'O intervalo entre as datas não pode ultrapassar os 60 dias',
					status: 'error',
				});
				return;
			}
		},
		verifyIsSelectedDate(dateString) {
			if (this.modeDate == 'interval') return;
			if (dateString == this.form.date.dateString) {
				return true;
			}
		},
		verifyModeDate(mode) {
			if (this.modeDate == mode) {
				return true;
			}
		},
		changeFilterDate(dateText) {
			this.modeDate = 'dateOption';
			const activeFilter = this.$store.getters.dataFilterPanel;
			this.form.date.dateString = dateText;
			activeFilter.date.dateString = dateText;
			
			this.$store.commit('setDataFilterPanel', {
				...activeFilter
			});

			if (dateText == 'today') {
				this.form.date.dateStart = moment().local().format('YYYY-MM-DD HH:mm');
			} else if (dateText == 'lastSeven') {
				this.form.date.dateStart = moment().local().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
			} else if (dateText == 'lastThirty') {
				this.form.date.dateStart = moment().local().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
			} else if (dateText == 'lastSixty') {
				this.form.date.dateStart = moment().local().subtract(60, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
			}

			if (dateText != 'today') {
				this.form.date.dateEnd = moment().local().format('YYYY-MM-DD HH:mm');
			}
		},

		applyFilters() {
			const intervalStartDate = moment(`${moment(this.form.date.intervalStart).format('YYYY-MM-DD')} ${this.form.date.intervalStartTime}`, 'YYYY-MM-DD HH:mm');
			const intervalEndDate = moment(`${moment(this.form.date.intervalEnd).format('YYYY-MM-DD')} ${this.form.date.intervalEndTime}`, 'YYYY-MM-DD HH:mm');
			const dateStart =
				this.modeDate == 'dateOption' ? this.form.date.dateStart : intervalStartDate.format('YYYY-MM-DD HH:mm');

			const dateEnd =
				this.modeDate == 'dateOption' ? this.form.date.dateEnd : intervalEndDate.format('YYYY-MM-DD HH:mm');

			const filtersApplyed = {
				date: {
					dateString: this.form.date.dateString || ['null'],
					dateStart: dateStart || ['null'],
					dateEnd: dateEnd || ['null'],
					modeDate: this.modeDate,
				},
				selectedOrganizationIds: 
					this.form.selectedOrganizationIds.length === 0 ? ['null'] : [...this.form.selectedOrganizationIds],
				selectedWorkspaceIds: 
					this.form.selectedWorkspaceIds.length === 0 ? ['null'] : [...this.form.selectedWorkspaceIds],
				selectedProjectIds: 
					this.form.selectedProjectIds.length === 0 ? ['null'] : [...this.form.selectedProjectIds],
				selectedFlowIds: 
					this.form.selectedFlowIds.length === 0 ? ['null'] : [...this.form.selectedFlowIds],
				resultExecutionFilter: 
					this.form.resultExecutionFilter.length === 0 ? ['null'] : [...this.form.resultExecutionFilter],
				translateResultExecutionFilter: this.translateStatus(),
			};
			this.$store.commit('setDataFilterPanel', {
				...filtersApplyed,
			});
			this.$root.$emit("applyFiltersIntegrationPanel");

			this.modalBus.$emit('close');


		}
	},
});
