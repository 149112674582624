
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import { workspacePermission } from '@/components/mixins/permissions/workspace';
import { mainSiderBarPermission } from '@/components/mixins/permissions/sidebar';
import { accessIntegrationPanelPermission } from '@/components/mixins/permissions/panelInfo';
import moment from 'moment';

import { appConfig } from '@/config/appConfig';
import { previousRoute } from '../router/index.ts';

import MenuOptions from '@/components/MenuOptions.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import HeaderFilterIntegration from '@/components/HeaderFilterIntegration.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

export default mixins(
	restApi,
	workspacePermission,
	mainSiderBarPermission,
	accessIntegrationPanelPermission,
).extend({
	components: {
		MenuOptions,
		ScrollInfinite,
		ModalButtonCancel,
		ModalButtonSuccess,
		HeaderFilterIntegration,
		AlertNotificationModal,
	},
	data() {
		return {
			page: 1,
			size: 10,
			isLoading: false,
			dataWorkspace: [],
			scrollInfinite: false,
			isLoadingPage: false,
			panel1Url: '',
			panel2Url: '',
			activeTab: 'resultIntegrations',
		};
	},
	computed: {
		...mapGetters('ui', {
			isCollapsed: 'sidebarMenuCollapsed',
		}),
		hasIntegration() {
			if (this.panel1Url || this.panel2Url) {
				return true;
			} else {
				return false;
			}
		},
		dateFilterSelected() {
			return this.$store.getters.dataFilterPanel.date.dateString;
		},
		quickFilterDateStart() {
			return this.$store.getters.dataFilterPanel.date.dateStart;
		},
		quickFilterDateEnd() {
			return this.$store.getters.dataFilterPanel.date.dateEnd;
		},
		dataFilter() {
			return this.$store.getters.dataFilterPanel;
		},
		organizationId() {
			return WebStorage.getItem('tenantId');
		},

		isManage() {
			if (this.$route.name === 'adminIntegrationPanel') return true;

			return false;
		},
	},
	async created() {
		this.isLoadingPage = true;
		await this.verifyPermissionUserAndInitiate();
		this.isLoadingPage = false;
	},
	mounted() {
		this.$root.$on("applyFiltersIntegrationPanel", this.initPanel);
	},
	beforeDestroy() {
		this.resetFilter();
	},
	methods: {
		resetFilter() {
			this.$store.commit('setDataFilterPanel', {
				date: {
					dateString: 'today',
					dateStart: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
					dateEnd: moment().endOf('day').format('YYYY-MM-DD HH:mm'),
				},
				selectedOrganizationIds: ['null'],
				selectedWorkspaceIds: ['null'],
				selectedProjectIds: ['null'],
				selectedFlowIds: ['null'],
				resultExecutionFilter: ['null'],
				translateResultExecutionFilter: ['null'],
			});
		},
		onTabSelect(tab: string) {
			this.activeTab = tab;
		},
		async loadPanel() {
			const idPanel = appConfig('vistra.painelResultadoIntegracoes');

			const parameters = [
				{
					id: 'a65b3e92-063c-4e00-8622-cbcd4bb5b478',
					descricao: 'Organização',
					Valor: this.isManage ? [...this.$store.getters.dataFilterPanel.selectedOrganizationIds] : [this.organizationId],
				},
				{
					id: 'b9a67559-4a8f-44b1-b919-380447b9619e',
					descricao: 'Espaço de Trabalho',
					Valor: [...this.$store.getters.dataFilterPanel.selectedWorkspaceIds],
				},
				{
					id: 'b8000b33-fe99-44f9-b90d-f84e9ba4dc16',
					descricao: 'projeto',
					Valor: [...this.$store.getters.dataFilterPanel.selectedProjectIds],
				},
				{
					id: 'fd9bf980-3691-4bc3-aaab-227e3f08cbaa',
					descricao: 'Fluxo',
					Valor: [...this.$store.getters.dataFilterPanel.selectedFlowIds],
				},
				{
					id: '8e813f28-6ffd-446d-a50a-29e7eae5dc24',
					descricao: 'Data Hora Inicio',
					Valor: [this.quickFilterDateStart],
				},
				{
					id: 'b46db80f-abf2-442e-808a-bd52a3aa8cfc',
					descricao: 'Data Hora Fim',
					Valor: [this.quickFilterDateEnd],
				},
				{
					id: '9e0a045a-336a-4ab6-9c26-a3a18983c20c',
					descricao: 'Status',
					Valor: [...this.$store.getters.dataFilterPanel.translateResultExecutionFilter],
				},
			];

			const idPanel2 = appConfig('vistra.painelFalhasIdentificadas');
			const parameters2 = [
				{
					id: 'a65b3e92-063c-4e00-8622-cbcd4bb5b478',
					descricao: 'Organização',
					Valor: this.isManage ? [...this.$store.getters.dataFilterPanel.selectedOrganizationIds] : [this.organizationId],
				},
				{
					id: 'b9a67559-4a8f-44b1-b919-380447b9619e',
					descricao: 'Espaço de Trabalho',
					Valor: [...this.$store.getters.dataFilterPanel.selectedWorkspaceIds],
				},
				{
					id: 'b8000b33-fe99-44f9-b90d-f84e9ba4dc16',
					descricao: 'projeto',
					Valor: [...this.$store.getters.dataFilterPanel.selectedProjectIds],
				},
				{
					id: 'fd9bf980-3691-4bc3-aaab-227e3f08cbaa',
					descricao: 'Fluxo',
					Valor: [...this.$store.getters.dataFilterPanel.selectedFlowIds],
				},
				{
					id: '8e813f28-6ffd-446d-a50a-29e7eae5dc24',
					descricao: 'Data Hora Inicio',
					Valor: [this.quickFilterDateStart],
				},
				{
					id: 'b46db80f-abf2-442e-808a-bd52a3aa8cfc',
					descricao: 'Data Hora Fim',
					Valor: [this.quickFilterDateEnd],
				},
				{
					id: '3192065f-6bc0-421d-afcd-124d624b60bd',
					descricao: 'Conector',
					Valor: ['null'],
				},
			];

			try {
				let panelResults = await this.restApi().sendIdPanelIntegration(idPanel, parameters);
				this.panel1Url = panelResults;
			} catch {
				this.$store.commit('activeAlert', {
					message: 'Erro ao carregar o painel de resultados',
					status: 'error',
				});
			}

			try {
				let panelInfoFail = await this.restApi().sendIdPanelIntegration(idPanel2, parameters2);
				this.panel2Url = panelInfoFail;
			} catch {
				this.$store.commit('activeAlert', {
					message: 'Falha ao carregar painel de falhas identificadas, tente novamente.',
					status: 'error',
				});

				this.isLoading = false;
			}
		},
		goToWorkspaceList() {
			this.$router.push({
				name: 'workspace',
			});
		},
		goToStoreList() {
			this.$router.push({
				name: 'integrationStore',
			});
		},

		async initPanel() {
			this.isLoading = true;
			await this.loadPanel();

			this.isLoading = false;
		},
		async verifyPermissionUserAndInitiate() {
			await this.validationAccessIntegrationPanel().then(async () => {
				if (this.isManage ? this.canViewAdminPanelIntegration() : this.canViewIntegrationPanel()) {
					await this.initPanel();
				} else {
					this.$store.commit('setData403', { show: true, prevPage: previousRoute });
				}
			});
		},
	},
});
